.App {
  background-color: white;
  height: 100%;
}

#root {
  height: 100%;
}

/* intentionally hide the debug handle. it's exposed in the 🐛 menu */
#djDebugToolbarHandle {
  display: none !important;
}
/* TODO(kyle): remove after bundle issues fixed */
#nonexistant-id {
  display: none;
}
