@import-normalize; /* bring in normalize.css styles */

*,
::before,
::after {
  box-sizing: inherit;
}

[tabindex='-1']:focus {
  outline: none;
}

html {
  font-size: 16px;
  height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  margin: 0;
  color: black;
  background-color: white;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 0.5rem;
}

h4 {
  margin-bottom: 0.25rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

hr {
  border: 0;
  border-color: var(--color-neutral300);
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  color: var(--color-neutral400);
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
  font-size: 0.875rem;
}

blockquote {
  margin: 0 0 1rem;
  padding-left: 1rem;
  border-left: 1px solid var(--color-neutral300);
}

img {
  vertical-align: middle;
}

th {
  white-space: nowrap;
}

input[type='radio']:disabled,
input[type='checkbox']:disabled {
  cursor: not-allowed;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--color-primary200);
}

button {
  line-height: inherit;
}
